// 全局class
@font-face {
  font-weight: 300;
  font-family: 'Fixture';
  font-style: normal;
  src: url('/fonts/Fixture-Light.otf') format('truetype');
  font-display: block;
}
@font-face {
  font-weight: 400;
  font-family: 'Fixture';
  font-style: normal;
  src: url('/fonts/Fixture-Regular.otf') format('truetype');
  font-display: block;
}
@font-face {
  font-weight: 500;
  font-family: 'Fixture';
  font-style: normal;
  src: url('/fonts/Fixture-Medium.otf') format('truetype');
  font-display: block;
}
@font-face {
  font-weight: 700;
  font-family: 'Fixture';
  font-style: normal;
  src: url('/fonts/Fixture-Bold.otf') format('truetype');
  font-display: block;
}
@font-face {
  font-weight: 900;
  font-family: 'Fixture';
  font-style: normal;
  src: url('/fonts/Fixture-Black.otf') format('truetype');
  font-display: block;
}

// 单行文本溢出
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// 清浮动
.clearfix {
  *zoom: 1;

  &::before,
  &::after {
    display: table;
    clear: both;
    content: ' ';
  }

  &::after {
    display: block;
    height: 1px;
    margin-top: -1px;
    visibility: hidden;
  }
}
