.swiper-pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.swiper-pagination-bullet {
  display: block;
  width: 4px;
  height: 4px;
  margin-right: 4px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
}
.swiper-pagination-bullet:last-child{
  margin-right: 0;
}

.swiper-pagination-bullet-active {
  background: #36FFC0;
}

.react-calendar {
  padding: 20px;
  background:  rgba(255, 255, 255, 0.14) !important;
  border: none !important;
  border-radius: 8px;
  box-shadow: 0 2px 24px 0 #040D0A !important;
}

.react-calendar__navigation {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  height: 20px !important;
  margin-bottom: 16px !important;
}
.react-calendar__navigation__arrow {
  min-width: auto !important;
  color: #36FFC0 !important;
  font-weight: 400 !important;
  font-size: 24px !important;
  line-height: 20px !important;
}
.react-calendar__navigation__prev2-button,.react-calendar__navigation__next-button {
  margin-right: 12px !important;
}
.react-calendar__navigation__label__labelText {
  color: #fff !important;
  font-weight: 700 !important;
  font-size: 16px;
}
.react-calendar__month-view__weekdays {
  margin-bottom: 7px;
  padding-bottom: 7px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0 !important;
  color: #fff !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}
.react-calendar__month-view__weekdays__weekday:first-child,.react-calendar__month-view__weekdays__weekday:last-child {
  color: #FF4AE7 !important;
}
abbr[title] {
  text-decoration: none !important;
}
.react-calendar__month-view__days__day {
  width: 32px !important;
  height: 32px !important;
  padding: 0 !important;
  color:rgba(255, 255, 255, 0.8) !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  border-radius: 50%;
}
.react-calendar__tile--now {
  color: #36FFC0 !important;
  font-weight: 600 !important;
  background: transparent !important;
}
.react-calendar__tile--active {
  background: #5703FF !important;
}
.react-calendar__tile:disabled {
  color: gray !important;
  background-color: transparent !important;
}